import React from 'react';
import {ResetPasswordForm} from "../../components/session";
import {Link} from 'gatsby';
import LayoutA from "../../layouts/LayoutA";
import {Icon} from '@blueprintjs/core'
import queryString from 'query-string';
import * as Paths from '../../constants/Paths'

class ResetPasswordPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      success: false
    }
  }

  render() {

    const {success} = this.state;

    var query = {};

    if (typeof window !== "undefined") query = queryString.parse(window.location.search);

    return (
      <LayoutA>
        <div className="LoginTemplate">
          <div className="LoginTemplate-floatingform">
            <h1 className="LoginTemplate-title">Create a New Password</h1>
            {!success ? (
              <ResetPasswordForm onSuccess={() => this.setState({success: true})} token={query.token}/>
            ) : (
              <div className="bp3-callout bp3-intent-primary">
                <h4 className="bp3-heading">Password reset!</h4>
                Please <Link to={Paths.LOGIN}>login again</Link> to continue.
              </div>
            )}
            <div className="LoginTemplate-buttonbar">
              <Link to={"/account/login"} className="LoginTemplate-buttonbar">
                <Icon icon="arrow-left" />
                <span className="pt-icon-standard pt-icon-arrow-left"/>Back to Login
              </Link>
            </div>
          </div>
        </div>
      </LayoutA>
    );
  }
}

export default ResetPasswordPage;